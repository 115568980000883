<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit User</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Update</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <ValidationObserver ref="editContactUser" v-slot="{ handleSubmit }">
          <form id="editContactUser" @submit.prevent="handleSubmit(onSubmit)">
            <CRow>
              <CCol lg="6">
                <CRow name="first_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">First Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_name"
                        :value="user.first_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="last_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Last Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="last_name"
                        :value="user.last_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="phone" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="user.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        :error="errors[0]"
                        @change="handleInput"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="email" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="user.email"
                        :error="errors[0]"
                        @input="handleInput"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";

import { extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
  },
  data() {
    return {
      user: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["dialingCodeWithCountryCode", "isFetchingFilter"]),
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
      };
    },
  },
  methods: {
    ...mapActions(["initContactFetch", "showToast"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.user, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.editContactUser.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        user_id: this.user?.user_id,
        first_name: this.user?.first_name,
        last_name: this.user?.last_name,
        phone: this.user?.phone,
        contact_id: this.user?.contact_id,
        contact_type_id: 10,
      };
      this.modalCallBack(true, finalPayload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    const userData = this.userDetails;
    this.user = {
      first_name: userData?.user?.first_name || null,
      last_name: userData?.user?.surname || null,
      phone: userData?.user?.contact_phone || null,
      email: userData?.user?.email || null,
      user_id: userData?.user?.id,
      contact_id: userData?.contact_id,
    };
    this.initContactFetch();
  },
};
</script>