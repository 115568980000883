<template>
  <div class="container-md col-lg-10 col-md-10 contact-user-list">
    <LoadingOverlay v-if="isCustomerFetch" />
    <div v-else>
      <h3 class="text-primary m-1 pt-1">
        User List
        <span class="nowrap" style="font-size: 20px"
          >({{ contactUsers.length || "0" }} Record{{
            contactUsers.length > 1 ? "s" : ""
          }})</span
        >
      </h3>

      <CDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="users"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        pagination
        class="p-2 border"
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex">
              <CButton
                color="primary"
                size="sm"
                square
                variant="ghost"
                class="mr-1"
                @click="assignFacility(item, index)"
                v-c-tooltip="'Assign Facility'"
                :disabled="item.isCustomerAdmin"
              >
                <i class="fa-solid fa-plus"></i>
              </CButton>
              <CButton
                color="primary"
                square
                variant="ghost"
                size="sm"
                class="mr-1"
                @click="editUser(item, index)"
                v-c-tooltip="'Edit'"
                ><i class="far fa-edit"></i
              ></CButton>
              <CButton
                color="danger"
                square
                variant="ghost"
                size="sm"
                class="mr-1"
                @click="openDeleteModal(item, index)"
                v-c-tooltip="'Delete'"
                :disabled="item.isCustomerAdmin"
                ><i class="fa-solid fa-trash-can"></i
              ></CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <EditContactUser
        v-if="contactUserEditModal.isShowPopup"
        :isShowPopup="contactUserEditModal.isShowPopup"
        :userDetails="contactUserEditModal.userDetails"
        :contactID="contactUserEditModal.contactID"
        @modalCallBack="contactUserEditModalCallBack"
      />
      <assignFacility
        v-if="assignFacilityModal.isShowPopup"
        :isShowPopup="assignFacilityModal.isShowPopup"
        :userDetail="assignFacilityModal.userDetails"
        @modalCallBack="assignFacilityModalCallBack"
      />
      <Modal
        v-if="deleteModal.isShowPopup"
        :isShowPopup="deleteModal.isShowPopup"
        :buttons="deleteModal.buttons"
        :modalColor="deleteModal.modalColor"
        :modalTitle="deleteModal.modalTitle"
        :modalContent="deleteModal.modalContent"
        :modalCallBack="deleteModalCallBack"
        :closeOnBackdrop="deleteModal.closeOnBackdrop"
        :stepperModal="deleteModal.stepperModal"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditContactUser from "@/containers/ContactUser/EditContactUser";
import assignFacility from "@/containers/ContactUser/AssignFacility";
import Modal from "@/components/reusable/Modal";
import _ from "lodash";
export default {
  components: {
    EditContactUser,
    assignFacility,
    Modal,
  },
  data() {
    return {
      limit: 10,
      small: false,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        { key: "name", _style: "width:25%" },
        { key: "user_role", _style: "width:25%" },
        { key: "email", _style: "width:25%" },
        { key: "phone", _style: "width:20%" },
        { key: "action", sorter: false, filter: false, _style: "width:5%" },
      ],
      contactUserEditModal: {
        isShowPopup: false,
        userDetails: null,
        contactID: null,
      },
      assignFacilityModal: {
        isShowPopup: false,
        userDetails: null,
      },
      deleteModal: {
        isShowPopup: false,
        userDetails: null,
        buttons: ["No", "Yes"],
        modalTitle: "Confirmation",
        modalColor: "danger",
        modalContent: "Do you want to Delete this User?",
        closeOnBackdrop: false,
        stepperModal: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "contactUsers",
      "isCustomerFetch",
      "getUser",
      "getOrgIDFromAccessToken",
    ]),
    users() {
      return (
        (this.contactUsers &&
          this.contactUsers.length &&
          this.contactUsers
            .map((val) => ({
              name: val?.name || "--",
              email: val?.email || "--",
              contact_type: val?.contact_type?.contact_type || "--",
              contact_id: val?.contact_id,
              user_role:
                (val?.user?.roles &&
                  val?.user?.roles
                    .map((v) =>
                      _.replace(
                        _.startCase(_.camelCase(v.role_type)),
                        "Customer",
                        ""
                      )
                    )
                    .join()) ||
                "--",
              phone: val?.user?.contact_phone.replace("^", "-") || "--",
              rawData: val,
              isCustomerAdmin:
                val?.user?.roles.some((v) => v?.role_type_id === 4) || false, //customer_admin role_type_id is 4
            }))
            .reverse()) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomerContactUser",
      "fetchUser",
      "updateCustomerUser",
      "deleteCustomerOrganisationContact",
    ]),
    editUser(item, index) {
      this.tooltipHide();
      let userData =
        this.contactUsers[
          this.contactUsers.findIndex((e) => e.contact_id === item.contact_id)
        ];
      this.contactUserEditModal.isShowPopup = true;
      this.contactUserEditModal.userDetails = userData;
      this.contactUserEditModal.contactID = userData.contact_id;
    },
    assignFacility(item, index) {
      this.assignFacilityModal.isShowPopup = true;
      this.assignFacilityModal.userDetails = item?.rawData;
    },
    contactUserEditModalCallBack(action, payload) {
      if (action === false) {
        this.contactUserEditModal.isShowPopup = false;
      } else if (action === true && payload) {
        this.updateCustomerUser(payload).then((res) => {
          this.contactUserEditModal.isShowPopup = false;
          this.fetchCustomerContactUser();
        });
      }
    },
    assignFacilityModalCallBack(action, payload) {
      this.assignFacilityModal.isShowPopup = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    openDeleteModal(item, index) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.userDetails = item.rawData;
      this.deleteModal.modalContent = `Do you want to Delete this User - ${item.name}?`;
    },
    deleteModalCallBack(action) {
      if (action === "Yes") {
        const { userDetails } = this.deleteModal;
        const { contact_id } = userDetails;
        const payload = {
          contact_id,
          organisation_id: this.getOrgIDFromAccessToken,
        };
        this.deleteCustomerOrganisationContact(payload).then((res) => {
          if (res?.status === 200) {
            this.fetchCustomerContactUser();
            this.deleteModal.isShowPopup = false;
          }
        });
      } else this.deleteModal.isShowPopup = false;
    },
  },
  mounted() {
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      this.fetchCustomerContactUser();
    });
  },
};
</script>
