<template>
  <div>
    <div v-if="!deleteModal.isShowPopup">
      <CModal
        :show.sync="isShowPopup"
        :close-on-backdrop="true"
        color="primary"
        size="lg"
      >
        <template #header>
          <h6 class="modal-title">Assign Facility - {{ userDetail.name }}</h6>
          <CButtonClose
            @click="modalCallBack(false)"
            class="text-black primary"
          />
        </template>
        <template #footer>
          <CButton
            type="button"
            color="primary"
            class="px-4"
            @click="modalCallBack(false)"
            >Close</CButton
          >
        </template>
        <div>
          <ValidationObserver ref="assignFacility" v-slot="{ handleSubmit }">
            <form id="assignFacility" @submit.prevent="handleSubmit(onSubmit)">
              <CRow class="mb-3">
                <CCol md="6">
                  <CRow>
                    <label class="col-lg-12 col-md-12 required"
                      >Select New Facility to Map with User</label
                    >
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="organisation_id"
                          :value="uploadData.organisation_id"
                          :options="
                            options && options['facilityList']
                              ? options['facilityList']
                              : []
                          "
                          @change="handleChangeSelect"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6" class="d-flex align-items-end">
                  <CButton
                    color="primary"
                    type="button"
                    @click="onSubmit"
                    :disabled="isLoading"
                    >Add<CSpinner
                      v-if="isLoading"
                      size="sm"
                      class="ml-1"
                      color="light"
                  /></CButton>
                </CCol>
              </CRow>
            </form>
          </ValidationObserver>
          <div class="table-responsive border">
            <table class="table mb-0 table-striped w-100">
              <colgroup>
                <col span="1" style="width: 80%" />
                <col span="1" style="width: 20%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Facility</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="organisation in linkedOrganisations"
                  :key="organisation.organisation_id"
                >
                  <td>
                    {{ organisation.organisation_name
                    }}<small
                      class="text-primary ml-1"
                      v-if="organisation.isCurrent"
                      >(current)</small
                    >
                  </td>
                  <td>
                    <a
                      name="delete-org-group"
                      class="cursor-action btn p-0"
                      :class="{ disabled: organisation.isCurrent }"
                      :disabled="organisation.isCurrent"
                      @click="openDeleteModal(organisation)"
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </a>
                  </td>
                </tr>
                <tr v-if="linkedOrganisations.length === 0">
                  <td colspan="2">
                    <p class="text-muted text-center">No Record Found</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CModal>
    </div>
    <Modal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :buttons="deleteModal.buttons"
      :modalColor="deleteModal.modalColor"
      :modalTitle="deleteModal.modalTitle"
      :modalContent="deleteModal.modalContent"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="deleteModal.closeOnBackdrop"
      :stepperModal="deleteModal.stepperModal"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Select from "@/components/reusable/Fields/Select";
import Modal from "@/components/reusable/Modal";

export default {
  props: {
    userDetail: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Select,
    Modal,
  },
  data() {
    return {
      uploadData: {},
      uploadPayload: {},
      isLoading: false,

      deleteModal: {
        isShowPopup: false,
        organisationDetails: null,
        buttons: ["No", "Yes"],
        modalTitle: "Confirmation",
        modalColor: "danger",
        modalContent: "Do you want to Delete this Facility Map?",
        closeOnBackdrop: false,
        stepperModal: false,
      },
    };
  },
  computed: {
    ...mapGetters(["groupedCustomerOrganisations", "getOrgIDFromAccessToken"]),
    options() {
      return {
        facilityList: this.groupedOrganisation || [],
      };
    },
    groupedOrganisation() {
      return (
        this.groupedCustomerOrganisations
          ?.filter(
            ({ customer_organisation_contact_id }) =>
              !customer_organisation_contact_id
          )
          .map((v) => ({
            code: v?.organisation_id,
            label: v?.organisation_name,
          })) || []
      );
    },
    linkedOrganisations() {
      return (
        this.groupedCustomerOrganisations
          ?.filter(
            ({ customer_organisation_contact_id }) =>
              customer_organisation_contact_id
          )
          .map((v) => ({
            ...v,
            isCurrent: v?.organisation_id === this.getOrgIDFromAccessToken,
          })) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchAllGroupedCustomerOrganisation",
      "createCustomerOrganisationContact",
      "deleteCustomerOrganisationContact",
      "showToast",
    ]),
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.uploadPayload = {
        ...this.uploadPayload,
        [name]: value ? value?.code || value?.id || value : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.assignFacility.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const { contact_id } = this.userDetail;
      const payload = {
        contact_id,
        organisation_id: this.uploadPayload?.organisation_id,
      };
      this.isLoading = true;
      this.createCustomerOrganisationContact(payload).then((res) => {
        this.isLoading = false;
        if (res?.status === 200) {
          this.resetForm();
          this.fetchAllGroupedCustomerOrganisation(contact_id);
        }
      });
    },
    resetForm() {
      this.uploadData = {};
      this.uploadPayload = {};
      this.$nextTick(() => {
        this.$refs.assignFacility?.reset();
      });
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    openDeleteModal(organisation) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.organisationDetails = organisation;
      this.deleteModal.modalContent = `Do you want to Delete this facility map - ${organisation.organisation_name}?`;
    },

    deleteModalCallBack(action) {
      if (action === "Yes") {
        const { organisationDetails } = this.deleteModal;
        const { contact_id } = this.userDetail;
        const payload = {
          contact_id,
          organisation_id: organisationDetails?.organisation_id,
        };
        this.deleteCustomerOrganisationContact(payload).then((res) => {
          if (res?.status === 200) {
            this.fetchAllGroupedCustomerOrganisation(contact_id);
          }
        });
      }
      this.deleteModal.isShowPopup = false;
    },
  },
  mounted() {
    const { contact_id } = this.userDetail;
    this.fetchAllGroupedCustomerOrganisation(contact_id);
  },
};
</script>
  